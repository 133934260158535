// Grid system
.main {
  //@include make-sm-column($main-sm-columns);
  .sidebar-primary & {
	//@include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }

  ul, ol {

	padding-top: 40px;
	padding-bottom:30px;
	list-style: none;
	margin-left:25px;
	li:before {
	  content: "•";
	  font-size: 264%;
	  left: -14px;
	  margin-left: -17px;
	  position: relative;
	  top: 14px;

	}
	li {

	  @media (min-width: $screen-sm-min) {

	  }

	  @media (min-width: $screen-md-min) {
		@include make-sm-column(6);
		margin-bottom: 30px;
		padding-right: 40px;

		@media (min-width: $screen-lg-min) {

		}

	  }

	}

  }
  ul.gform_fields {
	li {
	  @include make-sm-column(12);
	  padding-left:0;
	  padding-right:0;
	  margin-bottom:0;
	}
	li:before {
	  content: "";
	  font-size: inherit;
	  left: auto;
	  top: auto;
	  margin-left: auto;

	}
  }
}

.sidebar {
  //@include make-sm-column($sidebar-sm-columns);
}

article {
  //@include make-row();
  padding: 30px 0 0 0;
  @media (min-width: $screen-sm-min) {

  }

  @media (min-width: $screen-md-min) {
	padding: 0px 0 0 0;
  }

}

article.primary-section {

  @media (min-width: $screen-sm-min) {
	padding: 30px 0;
  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {
	padding: 75px 0;
  }

  div.container {

  }

}

#healing-in-context {

  //@include make-row;

  @media (min-width: $screen-sm-min) {

  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {
	padding: 225px 0;
  }

  div.container > div {
	@include make-sm-column(6);

  }

}

.two-column-wrapper {
  //@include make-row;
  @extend .container;

  @media (min-width: $screen-sm-min) {

  }

  @media (min-width: $screen-md-min) {
	padding-bottom: 90px;
  }

  @media (min-width: $screen-lg-min) {

  }

}

.sub-section {

  @include make-sm-column(6);
  padding-left: 0;
  padding-right: 0;

  &.column-1 .container {
	//margin-right:0;
  }
  &.column-2 .container {
	//margin-left:0;
  }

  div.container {
	width: auto;

	padding-right: 0;
	padding-left: 0;
	@media (min-width: $screen-sm-min) {
	  padding-right: 40px;
	}

	@media (min-width: $screen-md-min) {

	}

	@media (min-width: $screen-lg-min) {

	}
  }
}

.two-column-header {
  .primary-section {

  }
}

.sub-section > div.container > div {
  //@include make-sm-column(6);
}

.the_content_left, .the_content_right {


  @media (min-width: $screen-sm-min) {
	@include make-sm-column(6);
	padding-left: 0;
	padding-right: 30px;
  }

}

