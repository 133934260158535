// Grid settings
$main-sm-columns: $grid-columns;
$sidebar-sm-columns: 4;

// Colors
$brand-primary: #27ae60;

$brand-secondary: #0AA99D;
$brand-tertiary: #575855;
$brand-quad: #006587;
$background-color: #E8E9E0;

