.main {

  background-color: $background-color;


}
body {
  color: #575856;
  font-family: 'Montserrat', sans-serif;
  font-size:13px;
  line-height: 1.4;
  @media (min-width: $screen-sm-min) {

  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {
    font-size: 18px;

  }
}
p {
  margin-bottom:30px;



}

 p:nth-child(1) {
  margin-top: 40px;
}
h2 {

margin-top:0;
margin-bottom:0;

  @media (min-width: $screen-sm-min) {
    font-size:30px;
  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {
    font-size: 50px;
  }

}

h3 {

margin-top:0;
margin-bottom:0;
  color:#000;

  @media (min-width: $screen-sm-min) {
    font-size:16px;
  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {
    font-size: 24px;
  }
}

ul, ol {
  padding-left: 0px;
  line-height:1.2;
}

.acf-map {
  width: 100%;
  height: 310px;
  border: #ccc solid 0px;
  margin: 30px 0;
}

// create placeholder mixin
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

