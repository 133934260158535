header.banner.navbar.navbar-default.navbar-static-top {
  background-color:#fff;
}

.navbar {

  padding-top:25px;
  padding-bottom:25px;
  margin-bottom:0;


  ul {
    @extend .navbar-right;
    margin-top: 40px;
  }
  li {
    font-size: 16px;
  }

  .navbar-brand {
    float: left;
    //padding: 15px 15px;
    font-size: 0;
    line-height: 0;
height:auto;
  }

}

.two-column-header {

  .primary-section p:nth-child(1) {


    @media (min-width: $screen-md-min) {
      margin-top: 65px;
    }
  }

}

#healing-in-context {
  color: #fff;

  h2 {

    font-size: 40px;

	@media (min-width: $screen-sm-min) {

	}

	@media (min-width: $screen-md-min) {

	}

	@media (min-width: $screen-lg-min) {

	  font-size: 100px;

	}

  }





}

#healing-in-context, .our-services-wrapper {
  background-size: cover !important;

  @media (min-width: $screen-sm-min) {
    //background-size: auto 100% !important;

  }

  @media (min-width: $screen-md-min) {
    //background-size: 100% !important;

  }

  @media (min-width: $screen-lg-min) {

    //background-size: auto 100% !important;

  }

}
#the-cross-magill-difference {

  background-color: $brand-secondary;
  color:#fff;

}

#we-believe-in {

  color: $brand-quad;

}

#our-services {



}
.our-services-wrapper {
  h2 {
    color: $brand-quad;
  }
  p {

  }
}

#organizational {
}

#counselling {
}

#our-team {

}

.our-team-wrapper {

  img {

	@extend .img-responsive;

  }
  .our-team-profile-pic {
	margin-bottom: 30px;
  }

  h3,h2 {
    color: $brand-quad;
  }
  .our-team-desc, .our-team-desc a {
    color:#000;
	margin-bottom: 30px;
	font-weight: bold;
  }
}

#andrea-magill-m-ed {

}

#dan-cross-msc {
}

#contact-us {
  background-color: $brand-secondary;
  color:#fff;

  a {

    color:#fff;

  }

}


footer.content-info {
  .container {
    text-align:center;
  }
  a {
    float: none;
    width: 100px;
    display: inline-block;
    position: relative;
    height: 90px;
    img {
      width:100%;
      height:auto;
    }
  }
}