
.main .gform_wrapper .ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name span,
.main .gform_wrapper .ginput_complex.ginput_container.no_first_name.has_middle_name.has_last_name span,
.main .gform_wrapper .ginput_complex.ginput_container.has_first_name.has_middle_name.no_last_name span,
.main .gform_wrapper .ginput_complex .ginput_left,
.main .gform_wrapper .ginput_complex .ginput_right,
.main .gform_wrapper .ginput_complex input[type=text],
.main .gform_wrapper .ginput_complex input[type=url],
.main .gform_wrapper .ginput_complex input[type=email],
.main .gform_wrapper .ginput_complex input[type=tel],
.main .gform_wrapper .ginput_complex input[type=number],
.main .gform_wrapper .ginput_complex input[type=password],
.main .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_right select,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select,
.main .gform_wrapper .ginput_complex .ginput_full input[type=text],
.main .gform_wrapper input[type=text].large,
.main .gform_wrapper textarea.medium {

  width: 100% !important;
  margin-left: 0;
  color:#000;
}

.main .gform_wrapper .left_label textarea.medium,
.main .gform_wrapper .left_label input[type=text] {

  width: 63.5% !important;
  margin-left: 0;
  color: #000;



}

html > body .entry ul, .gform_wrapper ul.left_label {


width:100%
}

.main .gform_wrapper .left_label {

  [placeholder] {
    @include placeholder(#fff);



  }


  display: inline-block;

}

.main .gform_wrapper .left_label .gfield_label {

  display: inline-block;

}

.main .gfield_label {

  display:none;

}
.gform_footer.left_label {

  width: 70% !important;
  margin-bottom: 50px !important;

  @media (min-width: $screen-sm-min) {
    width: 65.5% !important;

  }

  @media (min-width: $screen-md-min) {

  }

  @media (min-width: $screen-lg-min) {

  }

}

.main .gform_wrapper .button {

  float: right;
  background-color: $brand-quad;
  color: #fff;
  border: none;

}

.main {
  .gform_wrapper ul.right_label li, .gform_wrapper form ul.right_label li, .gform_wrapper ul.left_label li, .gform_wrapper form ul.left_label li {

	margin-bottom: 0 !important;
	margin-top: 0 !important;

  }
  .gform_wrapper label.gfield_label + div.ginput_container {

	margin-top: 10px;

  }
}



.main .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_right select,
.main .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {

  background-color: #fff;
  color: #000;
  height: 40px;
  width: 100%;

}