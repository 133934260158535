// How to get started using Sass instead of Less:
//
// 1  Remove Bootstrap for Less: `bower uninstall bootstrap --save`
// 2. Install Bootstrap for Sass: `bower install bootstrap-sass-official --save`
// 3. Rename this file to `main.scss` and remove `main.less`
// 4. Rename `editor-style.less` to `editor-style.scss`
// 5. Update the `assets/manifest.json` styles dependencies from `.less` to `.scss`
// 6. Change variables from Less to Sass (https://discourse.roots.io/t/regarding-the-grid-help-translating-less-to-sass/3253)
//
// Feel free to remove this file if you're using Less

// bower:scss
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
// endbower
@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);
@import "common/variables";
@import "common/global";
@import "components/forms";
@import "components/grid";
@import "layouts/pages";
